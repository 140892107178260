@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
body {
  padding: 0;
  font-family: sans-serif;
  font-size: 62.5%;
}

.App-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;

  font-size: 1.2rem;
  background-image: url(../../static/media/fabric_of_squares_gray.da510f60.png);
  background-color: rgba(245, 245, 245, 0.9);
}

.App-link {
  color: #61dafb;
}

.SampleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.SampleBox {
  width: 20em;
  margin: 2em;
  padding: 10px;
  border-radius: 10px;
}

.SampleHeader {
  font-size: 1.25em;
}

@media only screen and (max-width: 600px) {
  html {
    display: none;
  }
}

html {
  font-family: "Montserrat", sans-serif !important;
}

